import React from "react";

import Layout from "components/Layout";
import SEO from "components/SEO";
import Posts from "components/Posts";
import AppUx from "components/AppUx";

const AppUxPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="Work" />
      <AppUx />
    </Layout>
  );
};

export default AppUxPage;
